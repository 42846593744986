// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.

// The list of file replacements can be found in `angular.json`.

export const environment = {
  GOOGLE_API_KEY:'AIzaSyDjq2E4UPeQ6yZ4sKVneGHwJfB_UalhGJY',
  production: false,
  // brand: '',
  // //Start Chat section for tlc chat
  liveAgentPod: 'd.la2-c1cs-hnd.salesforceliveagent.com', //PROD
  orgId: '00D0o000001B5aH',//PROD
  deploymentId: '572C5000000003O',//PROD
  buttonId: '573C50000000055',//PROD

  // liveAgentPod: 'd.la2-c1cs-ukb.salesforceliveagent.com', // FOR UAT 
  // orgId: '00D0T0000008kNm', // FOR UAT 
  // deploymentId: '5720T0000008OIo', // FOR UAT 
  // buttonId: '5730T0000008OK1',  // FOR UAT 
  //End Chat section for tlc chat 

  CountryName: "India",
  CountryCode: "in",
  CountryDialCode: "91",
  brandName: "Tlc Partners",
  languageArr: ['en', 'hi'], //Language array
  Content: "/content/tlc", //for ADDing AEM API Identifier request
  AppBrandName: 'AppBrandName.brand', // For Support Multi Languages like Language array
  taxPercentKey: 'GST',
  bundle_Id: 'com.tlcgroup.hotel.tlcgroup',
  headers: {
    client_id: 'HDRDGHTYUIKIOKSAAASS',
    client_secret: 'HDRDGHTYUIKIOKSAAASS',
    country: 'IN', // FIXME: should be part of geo coder
    nationality: 'Indian',
    language: 'en',
  },
  // baseUrl: 'https://hotelapp.tlcgroup-platform.com/api/', //Prod
  // baseUrl: 'https://hotelapp-dev-int.tlcgroup-test-platform.com/api/',//New SIT URL
  baseUrl: 'https://hotelapp-uat.tlcgroup-test-platform.com/api/', //New UAT URL
  getIPinfo: "https://pro.ip-api.com/json/?key=O80jLviQeItbkWK",

  appDBName: '__myhotelDB',
  // aem_base_url: '',
  // aem_url_exporter: '',// SIT STAGE


  //For Prod
  // aem_url_exporter: '', // PROD 
  // aem_base_url: '', //AEM Production URL
  //End Prod
  // aem_sildes_url: '',
  // sso_url:' https://sso-sit.tlcgroup-test-platform.com/saml/login'//SIT 
  sso_url: 'https://sso.tlcgroup-platform.com/saml/login', //Prod
  // sso_url: 'https://sso-uat.tlcgroup-test-platform.com/saml/login', //UAT

  //chat_ID: '573N000000000dY',
};

