import { Router, NavigationEnd } from '@angular/router';
import { Component, Output, EventEmitter, NgZone, OnInit } from '@angular/core';
import { Platform, PopoverController, ToastController } from '@ionic/angular';
import { InitService } from './services/init/init.service';
import { LocalizationService } from './services/core/localize/localization.service';
import { CommonService } from './services/common.service';
import { MenuController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { JAVASCRIPT, APP_COMPONENT } from '../app/app.constants';
import { OnboardingService } from './services/onboarding.service';
import { StorageService } from './services/core/storage/storage.service';
import { DashboardhomeService } from './services/dashboardhome.service';
import { AppStateService } from './services/init/app-state.service';
import { storageKeys, LOGIN_PAGE } from 'src/app/app.constants';
// import { ChatService } from './services/chat.service';
import { RedeemQRService } from './services/redeemQR.service';
import { EncryptionService } from './services/core/encryption/encryption.service';
import { AndroidIosDevideCheckSafetynetService } from './services/android-iOS-deviceCheck-safetynet';
// import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { EventService } from 'src/app/services/event/event.service';
import { filter } from 'rxjs/operators';
import { TokenCheck } from './services/tokenCheck.service';
import { ModalController } from '@ionic/angular';
import { PdfViewersComponent } from './modals/pdf-viewers/pdf-viewers.component';
import { Location } from '@angular/common';
import { PickerController } from "@ionic/angular";
import { AlertController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
declare var window: any
// import { TooltipComponent } from '@syncfusion/ej2-angular-popups';
declare var IRoot: any

// const options: PushOptions = {
//   android: {
//       senderID:'375015156198',
//       forceShow: true,
//       sound : true,
//       icon: 'fcm_push_icon'
//   },
//   ios: {
//       alert : true,
//       badge : false,
//       sound : true,
//       categories : {
//           shareAction : {
//             yes: {
//               callback: 'share',
//               title: 'Share',
//               foreground: true,
//               destructive: false
//             }
//           }
//       }
//     }
//   }
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

  @Output() empstatus = new EventEmitter();
  @Output()
  numberGenerated = new EventEmitter<number>();
  checkUUID: Number
  animated: boolean
  interval: void;
  isLogined: any;
  status: Promise<void>;
  ionicStorageEmployeeIdData: any;
  ionicStorageData: any = {};
  hotelPageAccess: any;
  subscription: any;
  uuid = null;
  jailBroker: boolean;
  demoEnalbe: boolean;
  appComopnetNetOff: boolean;
  counter: number = 0;
  // empstatus: any;
  // status: Promise<any>;
  // @ViewChild('tooltip', { static: true })
  // public tooltip: TooltipComponent;
  mobowPageAccess: any;
  outLetType: boolean
  popOverVal: number = 0;
  localpolicy = []
  userPageAccess: any;
  storappStateValue: any;


  constructor(
    private localizationService: LocalizationService,
    private platform: Platform,
    private initService: InitService,
    public toastController: ToastController,
    private router: Router,
    public commonService: CommonService,
    public tokenCheck: TokenCheck,
    // public chatService: ChatService,
    public redeemQRService: RedeemQRService,
    private menu: MenuController,
    public navCtrl: NavController,
    public storage: StorageService,
    public onboardingService: OnboardingService,
    public dashboardService: DashboardhomeService,
    public appState: AppStateService,
    public event: EventService,
    public encryptService: EncryptionService,
    public zone: NgZone,
    public deviceCheckSafetynetService: AndroidIosDevideCheckSafetynetService,
    // private uniqueDeviceID: UniqueDeviceID,
    private modalCtrl: ModalController,
    private popoverController: PopoverController,
    public http: HttpClient

  ) { }


  ngOnInit(): void {
   
   
    this.tokenCheck.uuid = this.checkUUID;
    this.platform.ready().then(() => {

      console.log("i am called for platform ready");
      localStorage.setItem(storageKeys.appComponent, JAVASCRIPT.FALSE)
      this.event.subscribe('sideMenuOpened', () => {
        // console.log('SidemenuCalled');
        this.zone.run(() => {
          this.openUpMenu();
          this.dataStore()

        })
      });

      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        //console.log('routerPage=>', event.url);
      })

      this.initializeApp()
    });
  }
 
  
  

  initializeApp() {
    // this.checkRootDevice() 
    // this.setDeviceUUID();
    // this.setAppId()
    // this.pushnotification.generatePushNotificationToken()

    this.localizationService.setDefaultAppLanguage();
    if (this.platform.is('android')) {
      // console.log("fun backz called==>")
      // this.registerBackButton();
    }
    this.checkInternetConnection();
    if (!this.platform.is('cordova')) {
      this.getIpAddress()
      if (localStorage.getItem(LOGIN_PAGE.loggedUser)) {
        this.initServiceCalledForWeb()
      }
     
    }
    // this.networkchekc.onNetworkChange().subscribe((status: ConnectionStatus) => {
    //   if (status == ConnectionStatus.Online) {
    //     this.commonService.internetStatus = true
    //     this.appComopnetNetOff = true
    //     this.checkInternetConnection()
    //     var checkBool = localStorage.getItem(storageKeys.appComponent);
    //     if (checkBool == JAVASCRIPT.FALSE) {
    //       localStorage.setItem(storageKeys.appComponent, JAVASCRIPT.TRUE)
    //       this.setDeviceUUID();
    //       this.initApp();
    //     }
    //   } else if (status == ConnectionStatus.Offline) {
    //     // console.log("1stTime off app", status)
    //     this.event.publish('dataLoadinit', '');
    //     this.splashScreen.hide();
    //     this.commonService.internetStatus = false
    //     this.appComopnetNetOff = false  // connect with dashboard page when app is offline
    //     this.checkInternetConnection()
    //     this.commonService.internetOff()
    //     var checkDasboard = localStorage.getItem(storageKeys.appComponent);
    //     if (checkDasboard == JAVASCRIPT.FALSE) {
    //       var enableToDashboard
    //       enableToDashboard = localStorage.getItem(LOGIN_PAGE.loggedUser)

    //     }
    //   }
    // });



  }




  setAppId() {
    // console.log("<----- app id--->")
    // if (this.platform.is('cordova')) {
    //   this.appVersion.getAppName().then((name) => {
    //     // console.log("App Name", name);
    //   });
    //   this.appVersion.getVersionCode().then((code) => {
    //     // console.log("App code", code);
    //   });
    //   this.appVersion.getVersionNumber().then((number) => {
    //     // console.log("App number-->", number);
    //     this.commonService.apkversion = String(number);
    //   });
    // } else {
    //   this.commonService.apkversion = '1.0.0';
    // }
  }


  async setDeviceUUID() {
    //console.log("setDeviceUUID")
    if (this.platform.is('cordova')) {
      if (this.platform.is('android')) {
        //console.log("android check ")
        // this.commonService.uuid = this.device.uuid;
        this.tokenCheck.uuid = this.commonService.uuid;
      } else if (this.platform.is('ios')) {
        //console.log("ios check ")

        // this.commonService.uuid = this.device.uuid;
        this.tokenCheck.uuid = this.commonService.uuid;


      }

    }

    else {
      // console.log("check uuid ")
      // this.checkUUID = Math.floor(1000 * Math.random());
      // console.log("  this.commonService.uuid", this.checkUUID)
      // this.commonService.uuid = this.checkUUID;

      // this.tokenCheck.uuid = this.checkUUID;
    }
  }


  // Jail broker code and check device is rooted true or false 
  // checkRootDevice() {
  //   if (this.platform.is('cordova')) {
  //     IRoot.isRooted((booleanVal) => {
  //     console.log('IRoot.isRooted success: ', booleanVal);
  //     console.log("typee of root  ", typeof(booleanVal))
  //         if(booleanVal === true){
  //             if (this.platform.is('android')) {
  //                 console.log("android hit ")
  //                 this.commonService.errorHandlerForRootAndroid()
  //             }
  //             else {
  //                 console.log("ios hit yaahhhhhh ")
  //                 this.commonService.errorHandlerForRootIos()
  //             }
  //         }else{
  //           console.log("hitt")
  //         }
  //     }, (err) => {
  //       console.log('IRoot.isRooted error:', err);
  //     });
  //   }
  // }

  // private async initApp() {
  //   // This functionFor chat Before Login For Vistor 
  //   if (this.platform.is("cordova")) {
  //     // await this.chatService.initializechat();
  //   }
  //   //Device Check And Safetynet
  //   if (this.platform.is('cordova')) {
  //     if (this.platform.is('ios')) {
  //       //console.log(" Device Check And Safetynet Funtion ios")
  //       this.deviceCheckSafetynetService.tokenCreation().then(res => {
  //         this.initServiceCalled()
  //       })
  //     }
  //     else if (this.platform.is('android')) {
  //       //console.log(" Device Check And Safetynet Funtion")
  //       this.deviceCheckSafetynetService.tokenCreation().then(res => {
  //         // console.log("resdevice==>", res)
  //         this.initServiceCalled()
  //       })
  //     }
  //   }
  //   this.checkInternetConnection();
  //   if (!this.platform.is('cordova')) {
  //     this.initServiceCalledForWeb()
  //   }
  // }

  // async initServiceCalled() {
  //   this.commonService.initloader()
  //   this.initService.encryptiongetInitApp().then(async res => {
  //     //console.log("res init =>", res)
  //     this.ionicStorageEmployeeIdData = this.appState.getAppData()
  //     this.initService.checkAppStateData().then(res => {

  //       this.commonService.dismissLoaderInit()
  //       if (this.ionicStorageEmployeeIdData.loginCheck) {
  //         //console.log("APP INIT loginCheck=>", this.ionicStorageEmployeeIdData.loginCheck)
  //         this.initService.afterloginAuthFun();

  //       } else {
  //         // console.log("else Hide splach")
  //         // When auth authenticatekey api response not get  
  //         // this.splashScreen.hide();
  //       }
  //     })


  //     this.ionicStorageEmployeeIdData = this.appState.getAppData();
  //     if (this.ionicStorageEmployeeIdData.userPageAccess == null || this.ionicStorageEmployeeIdData.userPageAccess == undefined) {
  //     }
  //     else {
  //       this.dataStore()
  //     }

  //   });
  // }

  async initServiceCalledForWeb() {
    // console.log("initServiceCalledForWeb ->>>>>>>>>");
  


    if (!localStorage.getItem(LOGIN_PAGE.loggedUser)) {
      console.log("________________+++++++++++")
      this.commonService.initloader()
      this.initService.encryptiongetInitApp().then(async res => {
        this.ionicStorageEmployeeIdData = await this.appState.getAppData()
        this.initService.checkAppStateData().then(res => {
          this.commonService.dismissLoaderInit()

        })
      });
      this.router.navigateByUrl('/welcome-page')
    } else {
      this.initService.checkAppStateData().then(res => {
      
      })
      console.log("**********")
      this.ionicStorageEmployeeIdData = await this.appState.getAppData()
      this.dataStore()
      this.router.navigateByUrl('web-dashboard')

    }
  

  }
  getIpAddress() {
    this.http.get(environment.getIPinfo).subscribe(
      (res: any) => {
        if (res && res.query) {

          localStorage.setItem('ip', res.query);
        }
      },
      (err) => {
        console.log('url error ====>>>>', err);
      }
    );
  }

  dataStore() {
    this.ionicStorageEmployeeIdData = this.appState.getAppData();
    // console.log("dataStore FuntionHit")
    if (this.ionicStorageEmployeeIdData.userPageAccess == null || this.ionicStorageEmployeeIdData.userPageAccess == undefined) {
    }

    this.zone.run(() => {
      this.outLetType = this.ionicStorageEmployeeIdData?.userDetails?.orderIn
      //  console.log("outLetType this.outLetType",this.outLetType)
      this.hotelPageAccess = this.ionicStorageEmployeeIdData?.userPageAccess?.userPageAccess
      this.mobowPageAccess = this.ionicStorageEmployeeIdData?.userPageAccess?.userPageMbowAccess
      if (!this.outLetType) {
        this.localpolicy = this.hotelPageAccess;
      } else {
        this.localpolicy = this.mobowPageAccess;
      }
    })


  }





  private checkInternetConnection() {
    //console.log("checkInternetConnection")
    this.commonService.internetStatus = navigator.onLine;
    //console.log(" this.commonService.internetStatus", this.commonService.internetStatus)
    // this.network.onConnect().subscribe(data => {

    //   this.commonService.internetStatus = true;

    //   // this.displayNetworkUpdate(data.type);
    // });
    // this.network.onDisconnect().subscribe(data => {

    //   this.commonService.internetStatus = false;
    //   this.event.publish('dataLoadinit', '');
    //   //console.log("hide==> onDisconnect splach", this.commonService.internetStatus)
    //   // this.splashScreen.hide();
    //   // this.displayNetworkUpdate(data.type);
    // });
  }

  /**
   * This method is used to register the on pause and resume events.
   * Not used for now. If require to open Lock screen on resume just uncomment the code.
  */
  // private registerLockScreen() {
  //   this.platform.pause.subscribe(() => {
  //   });

  //   this.platform.resume.subscribe(() => {
  //     ////console.log("resume and navigating to lock screen");
  //     // this.lockService.showLockScreen();
  //   });
  // }

  //  Final back exit button //
  // registerBackButton() {
  //   var loggedinuser
  //   loggedinuser = localStorage.getItem(LOGIN_PAGE.loggedUser)
  //   // console.log("loggedinuser", typeof (loggedinuser), loggedinuser);
  //   this.platform.backButton.subscribeWithPriority(9999, (e) => {
  //     loggedinuser = localStorage.getItem(LOGIN_PAGE.loggedUser)
  //     // console.log("loggedinuser", typeof (loggedinuser), loggedinuser);
  //     if (this.router.url === '/welcome-page') {
  //       // console.log("called for dashboard and welcome page 1", this.counter);
  //       if (this.counter == 0) {
  //         this.counter++;
  //         this.alertController.dismiss();
  //         // this.tooltip.close();
  //         if (this.popOverVal == 1) {
  //           this.popoverController.dismiss();
  //         }

  //         this.commonService.presentToast("Press again to exit Application.");
  //         setTimeout(() => {
  //           // console.log("called for dashboard and welcome page 2 ", this.counter);
  //           this.counter = 0;
  //           // console.log("called for dashboard and welcome page 4 ", this.counter);
  //         }, 2000);
  //       } else {
  //         // this.counter = 0;
  //         // console.log("called for dashboard and welcome page 3", this.counter);
  //         navigator["app"].exitApp();
  //       }
  //     }
  //     else if (this.router.url === '/dashboard') {
  //       // console.log("dashboard");
  //     }
  //     else if (loggedinuser == null && this.router.url === '/select-outlet') {
  //       // console.log("clicked on select outlet", loggedinuser);
  //     }
  //     else if (loggedinuser == 'true' && this.router.url === '/select-outlet') {
  //       // console.log("should be back now");
  //       this.location.back();
  //     }
  //     else if (this.router.url === '/privacy-policy' || this.router.url === '/login' || this.router.url === '/reset-password') {
  //       this.location.back();
  //     }
  //     else {
  //       // console.log(" first else value ==>",);
  //       this.pickerCtrl.dismiss();
  //       this.modalCtrl.dismiss();
  //       this.alertController.dismiss();
  //       // this.tooltip.close();
  //       this.location.back();
  //       // console.log('goBack()...');

  //     }
  //   })
  // }






  //menu Hide and routing funtion sidemenu 

  openPage(page) {
    
    localStorage.removeItem('RestaurantView');
    // this.tooltip.close();
    if (this.popOverVal == 1) {
      
      this.popoverController.dismiss();
    }
    this.getContent().scrollToTop(100);
    switch (page) {
      case APP_COMPONENT.feedback:
        this.router.navigateByUrl('feedback');
        break;
      case APP_COMPONENT.reservationList:
        this.router.navigateByUrl('reservation-list');
        break;
      case APP_COMPONENT.faq:
        this.router.navigateByUrl('faqs');
        break;
      case APP_COMPONENT.setting:
        this.router.navigateByUrl('setting');
        break;
      case APP_COMPONENT.redemption:
        this.router.navigateByUrl('redemption-log');
        break;
      case APP_COMPONENT.edit:
        this.router.navigateByUrl('edit-profile');
        break;
      case APP_COMPONENT.viewreservations:
        this.router.navigateByUrl('view-reservations');
        break;
      case APP_COMPONENT.testSlide:
        this.router.navigateByUrl('teset-side');
        break;
      case APP_COMPONENT.reservationsmaster:
        this.router.navigate(['reservation-master']);
        break;
      case APP_COMPONENT.switchoutlet:
        this.router.navigate(['select-outlet']);
        break;
      case APP_COMPONENT.search:
        this.commonService.memberseachEnable = false
        this.router.navigate(['member-search']);
        break;
      case APP_COMPONENT.exception:
        this.router.navigate(['record-exception']);
        break;
      case APP_COMPONENT.logout:
        this.dataStore()
        this.logout();
        if (this.platform.is('cordova')) {
          // this.chatService.chatOffSection();
        }
        break;
      case APP_COMPONENT.dashboard:
        this.router.navigateByUrl('user-dashboard')
        break;
      case APP_COMPONENT.outletdetails:
        this.router.navigateByUrl('outlet-details')
        break;
      case APP_COMPONENT.offersevents:
        this.router.navigateByUrl('offer-cheque')
        break;
      case APP_COMPONENT.outletMaster:
        this.router.navigateByUrl('outlet-master')
        break;
      case 'editTable':
        this.router.navigateByUrl('table-list')
        break;
      case 'tableMaster':
        this.router.navigateByUrl('table-master')
        break;
      case 'OutletSection':
        this.router.navigateByUrl('outlet-section')
        break;
      case 'addTags':
        this.router.navigateByUrl('tags')
        break;

      case APP_COMPONENT.openReservation:
        this.router.navigateByUrl('open-reservation').then(() => {

        });
        break;
      case APP_COMPONENT.appTour:
        this.appTourPdf()
        break;
      case APP_COMPONENT.setupOutlet:
        this.router.navigateByUrl('setup-outlet')
        break;
      case APP_COMPONENT.setupRules:
        this.router.navigateByUrl('setup-rules')
        break;
      case APP_COMPONENT.setupWidget:
        this.router.navigateByUrl('setup-widget')
        break;
      case APP_COMPONENT.setupMenu:
        this.router.navigateByUrl('setup-menu')
        break;
      case APP_COMPONENT.menu:
        this.router.navigateByUrl('menu')
        break;
      case APP_COMPONENT.editMenu:
        this.router.navigateByUrl('menu', { state: { comingFor: "publish" } })
          .then(() => {
            this.menu.close();
          });
        break;
      case APP_COMPONENT.blockService:
        this.router.navigateByUrl('block-service')
        break;
      case APP_COMPONENT.itemNotAvailable:
        this.router.navigateByUrl('item-not-available')
        break;
      case APP_COMPONENT.revenueManagement:
        this.router.navigateByUrl('revenue-management')
        break;
      case APP_COMPONENT.updateItem:
        this.router.navigateByUrl('update-item')
        break;
    }
  }

  closeMenu() {
    this.menu.enable(false)
    this.menu.isOpen().then((res) => {
      // this.tooltip.close();
      if (this.popOverVal == 1) {
        this.popoverController.dismiss();
      }
      if (res) this.menu.close();
    });
    this.getContent().scrollToTop(100);
  }

  //..logout api starts here//

//..logout api starts here//
logout() {
  this.appState.saveLoginCheck(JAVASCRIPT.BLANK);
  localStorage.removeItem(LOGIN_PAGE.loggedUser)
  this.commonService.presentLoader();
  this.dashboardService.emplogout(this.ionicStorageEmployeeIdData.userDetails.employeeId).subscribe(res => {
    this.commonService.dismissLoader();
    if (res) {
      this.commonService.sessionExpireEnable = false
      this.initService.hidesplashScreen = false
      this.storage.remove(storageKeys.loginCheck)
      localStorage.removeItem(LOGIN_PAGE.loggedUser)
      this.commonService.dashboardApi = false
      this.storage.remove(storageKeys.userDetails)
      this.storage.remove(storageKeys.TestLogin)
      this.storage.remove(storageKeys.sessionToken)
      this.appState.saveSessionToken(JAVASCRIPT.BLANK);
      this.appState.saveUserDetails(JAVASCRIPT.BLANK);
      // this.appState.saveUserDetailsPageAccess(JAVASCRIPT.BLANK)
      this.appState.saveEmployeIdforHeader(JAVASCRIPT.BLANK);
      this.storage.remove(storageKeys.userPageAccess)
      this.storage.remove(storageKeys.employeeidHeader)
      this.storage.remove(storageKeys.userDetailsForChat)
      this.appState.saveLoginCheck(JAVASCRIPT.BLANK);
      // this.storage.storageClear();
      this.storage.storageremoveItem(storageKeys.userPreferredLanguage);
      this.storage.storageremoveItem(storageKeys.aemData)
      this.storage.storageremoveItem(storageKeys.appComponent)
      this.storage.storageremoveItem('currentTime')
      localStorage.removeItem(LOGIN_PAGE.headerDetails)
      localStorage.removeItem(LOGIN_PAGE.aemUrl)
      localStorage.removeItem(storageKeys.anonymousKey)
      localStorage.removeItem(storageKeys.authenticatekey)
      this.commonService.csrfToken = ''
      this.localizationService.setDefaultAppLanguage();
      this.router.navigate(['welcome-page'])
      // this.onboardingService.encryptiongetInitApp().then(async res => {

      // })
      this.storage.get(storageKeys.welcomeButtonStatus, '').then(res => {

      })

    }
    else {
      this.commonService.dismissLoader();
    }
  }, err => {
    this.commonService.dismissLoader();
    this.commonService.errorHandler(err);
  })
}

  openBarCodeScannerModal() {
    // console.log("open bar code scanner model");
    if (this.platform.is("cordova")) {
      // console.log("open bar code scanner model for cordova");
      // this.redeemQRService.barCodeScanner();
    }
  }

  async appTourPdf() {
    var outletPdf = ''
    if (this.outLetType == true) {
      outletPdf = this.ionicStorageEmployeeIdData.appInitData.mbowAppTourUrl
    } else {
      outletPdf = this.ionicStorageEmployeeIdData.appInitData.appTourUrl
    }
    if (this.platform.is("cordova")) {
      // if (this.platform.is('ios')) {
      //   const browser = this.iab.create(outletPdf, PDFVIEWERS_MODAL.iab_blank, PDFVIEWERS_MODAL.iabOption);
      // } else {
      //   this.openModel(outletPdf)
      // }
    } else {
      this.openModel(outletPdf)
    }



  }
  async openModel(outletPdf) {
    const modal = await this.modalCtrl.create({
      component: PdfViewersComponent,
      componentProps: {
        "pdfURL": {
          getPdfURL: outletPdf,
          pdfHeading: 'App Tour'
        }
      }
    });
    return await modal.present();
  }

  getContent() {
    return document.querySelector('ion-content');
  }

  updateMenuState() {
    this.getContent().scrollToTop(100);
    this.onScroll();
  }

  onScroll() {

    if (this.popOverVal == 1) {
      this.popoverController.dismiss();
      this.popOverVal = 0
    }
    // if (document.getElementsByClassName('e-tooltip-wrap').length > 0) { this.tooltip.close(); }
  }

  ionViewWillLeave() {
    this.onScroll();

  }
  openUpMenu() {
    this.dataStore()
    this.getContent().scrollToTop(100);
  }

  popover(event: any) {
    // con ̰sole.log("popover called", event);
    this.popOverVal = this.popOverVal + 1;
    if (this.popOverVal >= 1) {
      this.dashboardService.closeTooltip =  this.popoverController.dismiss();
      this.popoverController.dismiss();
      event.stopPropagation();
      this.popOverVal = 1
    }
  }

  contentclicked() {
    if (this.popOverVal == 1) {
      this.popOverVal = this.popOverVal + 1;
      this.popoverController.dismiss();
      this.popOverVal = 0
    }
  }




}